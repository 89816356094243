<template>
   <div>
        <div class="pageTwo_wrap">
            <transition enter-active-class="animated rotateInUpLeft" leave-active-class="fadeOut">
                <div class="pageTwo_top" v-if="show">
                    <div class="title">关于武汉世纪星辰</div>
                    <div class="des">始终如一把客户的需求与产品的品质与售后放到第一位</div>
                </div>
            </transition>
            <div class="company">
                <div class="company_list">
                    <transition enter-active-class="animated fadeInLeft" leave-active-class="fadeOut">
                        <div class="company_left" v-if="show">
                            <p class="title">武汉世纪星辰</p>
                            <div class="des">公司在2011年3月成立，注册资本100万，公司一直以来，与国内外众多的知名品牌的实验室仪器制造商保持着紧密的合作关系。一直致力于把国内外最先进的仪器设备推荐给广大的科研教学工作者。公司客户现已遍及全省各大高校、科研院所、化工制药、食品卫生、环境环保、农林畜牧等单位，在与客户的长期合作中，始终如一把客户的需求与产品的品质与售后放到第一位</div>
                            <span class="btn" @click="goAboutUs">了解更多 +</span>
                        </div>
                    </transition>
                    <transition enter-active-class="animated fadeInDownBig" leave-active-class="fadeOut">
                        <div class="company_right clearfix" v-if="show">
                            <div class="wrap fl">
                                <div class="num">材料科学,生命科学,地质试验,地质调查</div>
                            </div>
                            <div class="wrap wrapThree wrap_right fl">
                                <div class="title">主营产品</div>
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="company_list">
                    <transition enter-active-class="animated bounceInUp" leave-active-class="fadeOut">
                        <div class="company_left cmpanyBac" v-if="show"></div>
                    </transition>
                    <transition enter-active-class="animated bounceInRight" leave-active-class="fadeOut">
                        <div class="company_right clearfix" v-if="show">
                            <div class="wrap wapTwo fl">
                                <div class="bottom">行业领域</div>
                            </div>
                            <div class="wrap wrap_right fl">
                                <div class="bottom">
                                    <div class="advantage"><span></span> 美国海洋光学OceanOptic</div>
                                    <div class="advantage"><span></span>台湾力钧生物</div>
                                    <div class="advantage"><span></span>昆山小美超声</div>
                                    <div class="advantage"><span></span>北京泊菲莱</div>
                                    <div class="advantage"><span></span>加拿大 Polymer Source</div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {};
    },
    props:{
        show:{
            type:Boolean,
            default: false
        }
    },

    computed:{},

    mounted () {
       
    },

    methods: {
        goAboutUs(){
            this.$router.push('/aboutUs')
        }
    }
}
</script>

<style lang='scss' scoped>
.pageTwo_wrap{
    position: absolute;
    width:1200px;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
}
.pageTwo_top{
    text-align: center;
    .title{
        font-size: 30px;
        margin-bottom: 20px;
        color: #627afd;
    }
    .des{
        font-size: 14px;
        color:#5e5e5e;
        line-height: 1.8;
        text-align: center;
    }
}
.company{
    margin-top: 40px;
    .company_list{
        display: flex;
        .company_left{
            width:40%;
            color:#fff;
            background: #004189;
            padding:20px;
            box-sizing: border-box;
            .title{
               font-size: 24px; 
            }
            .des{
                font-size: 14px;
                line-height: 1.8;
                max-width: 380px;
                margin:20px 0;
                max-height: 70px;
                overflow : hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .company_right{
            background: #fff;
            width:60%;
            background-size: 100%;
            .wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding:0 50px;
                // min-width: 320px;
                width: 40%;
                height: 100%;
                box-sizing: border-box;
                .num{
                    text-align: center;
                    margin-bottom: 20px;
                    line-height: 40px;
                    font-size: 20px;
                    color: #848383;
                    span{
                        font-size: 50px;
                        color:#004189;
                        margin-right:10px;  
                    }
                }
                .bottom{
                    text-align: center;
                    font-size: 16px;
                    .advantage{
                        font-size: 18px;
                        line-height: 40px;
                        color: #848383;
                    }
                }
            }
            .wrap_right{
                width: 60%;
            }
            .wapTwo{
                background: #004189;
                color:#fff;
                .bottom{
                    font-size: 32px;
                }
            }
            .wrapThree{
               background: url('../../../assets/images/167005370.jpg'); 
               background-size: 100%;
               position: relative;
                .title{
                    color: #fff;
                    font-size: 32px;
                    text-align: center;
                }
            }
        }
        .cmpanyBac{
            height:224px;
            background-image: url('../../../assets/images/company.jpg');
            background-size: 100%;
        }
    }
}

</style>