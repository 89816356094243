<template>
    <div>
        <div class="pageFourPage">
            <transition enter-active-class="animated fadeInRightBig" leave-active-class="fadeOut">
                <div class="new_top" v-show="show">
                    <div class="title">新闻资讯</div>
                    <div class="dec">真实性、及时性和准确性</div>
                </div>
            </transition>
            <div class="new_wrap">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="new_right_top" @click="goNews">
                            <img :src="newLIst[0].img" alt="" height="400px">
                            <div class="title">{{newLIst[0].title}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="24">
                                <div class="new_right_top" @click="goNews">
                                    <img :src="newLIst[1].img" alt="" height="406">
                                    <div class="title">{{newLIst[1].title}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <div class="new_right_bottom">
                    <div class="list" v-for="item in newList" :key="item.id" @click="goNews">
                        <div>{{item.name}}</div>
                        <div>{{item.tiem}}</div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            newList:[
                {id:4,name:'英斯特朗活动：参加中国国际科学仪器及',tiem: '2017-05-03'},
                {id:5,name:'英斯特朗动态疲劳业务全球事件连线',tiem: '2017-04-10'},
                {id:6,name:'中国医用冷藏箱温度稳定的新突破',tiem: '2017-10-19'},
            ],
            newLIst:[
                {
                    id:1,
                    img:require('@/assets/images/news/hangye1.jpg'),
                    title:'Tecan资讯 | 工作站如何加速生物仿制',
                    date:'2017',
                    time: '10-19',
                    content:'10月9日至13日，由教育部高等学校材料类专业教学指导委员会主办，南昌大学、南昌航空大学、江西科技师范大学联合承办的第六届“徕卡杯”全国大学生金相比赛在南昌取得'
                },
                {
                    id:2,
                    img:require('@/assets/images/news/hangye2.jpg'),
                    title:'英斯特朗活动：参加中国国际科学仪器及',
                    date:'2017',
                    time: '05-18',
                    content:'徕卡DVM6演示—烽火科技站'
                },
                {
                    id:3,
                    img:require('@/assets/images/news/new3.jpg'),
                    title:'我公司代表参加美墨尔特代理商大会',
                    date:'2017',
                    time: '05-03',
                    content:'鹰击万里，追梦笃行; 2017年3月24日至26日，美墨尔特全体代理商齐聚江南魅力城市——江苏无锡。总结2016'
                },
                {
                    id:4,
                    img:require('@/assets/images/news/new4.jpg'),
                    title:'帝肯TECAN：如何开发最佳的荧光检测方案',
                    date:'2017',
                    time: '04-10',
                    content:'借助荧光检测技术您可以进行一些极端敏感，高稳健性且具有广泛动态范围的检测实验，而试验的成功取决于下文所述的几点：激发波长（Ex）和发射波长（Em）的谨慎选择'
                },
            ],
        };
    },
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },

    computed:{},

    mounted () {},

    methods: {
        goNews(){
            this.$router.push('/news')
        }
    },
}
</script>

<style lang='scss' scoped>
.pageFourPage{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width:1200px;
}
.new_top{
    text-align: center;
    margin-top:90px;
    .title{
        font-size: 30px;
        margin-bottom: 20px;
        color: #627afd;
    }
    .dec{
        font-size: 14px;
        color:#5e5e5e;
        line-height: 1.8;
        text-align: center;
    }
}
.new_wrap{
    margin-top:40px;
    cursor: pointer;
    .new_right_top{
        background: #fff;
        &:hover{
            box-shadow: 0px 6px 8px 2px rgba(8, 15, 36, 0.1);
        }
        .title{
            font-size: 20px;
            margin-bottom: 15px;
            color: #fff;
            background: #004189;
            padding:20px;
        }
        img{
            width:100%;
            padding:20px;
            box-sizing: border-box;
        }
    }
    .new_right_bottom{
        background: #fff;
        padding:20px;
        .list{
            cursor: pointer;
            line-height: 42px;
            font-size: 16px;
            border-bottom: 1px solid #999;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover{
                color:#004189;
                font-size: 18px;
            }
        }
    }
}
</style>