<template>
   <div class="pageFivePage" @click="goPhoto">
       <transition enter-active-class="animated fadeInLeft" leave-active-class="fadeOut">
            <div class="new_top" v-if="show" style="margin-bottom:90px;">
                <div class="title">企业相册</div>
                <div class="dec">灿烂明天需要努力追寻，美好回忆就让影像记录;</div>
            </div>
       </transition>
       <el-carousel :autoplay="true" type="card">
            <el-carousel-item v-for="(item,index) in photoList" :key="index" class="carousel_item">
                <img :src="item.image" alt="">
            </el-carousel-item>
        </el-carousel>
   </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            photoList:[
                {image: require('@/assets/images/photo/photo0.jpg')},
                {image: require('@/assets/images/photo/photo1.png')},
                {image: require('@/assets/images/photo/photo2.png')},
                {image: require('@/assets/images/photo/photo3.png')},
                {image: require('@/assets/images/photo/photo4.png')},
                {image: require('@/assets/images/photo/photo5.png')},
                {image: require('@/assets/images/photo/photo6.png')}
            ],
        };
    },
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },

    computed:{},

    mounted () {},

    methods: {
        goPhoto(){
            this.$router.push({path:'/photo'})
        }
    },
}
</script>

<style lang='scss' scoped>
.pageFivePage{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width:1200px;
    .new_top{
        text-align: center;
        .title{
            font-size: 30px;
            margin-bottom: 40px;
            color: #627afd;
        }
    }
    .carousel_item{
        width:100%;
        img{
            height:100%;
        }
    }
}
</style>