<template>
    <div class="pageThreePage">
        <transition enter-active-class="animated fadeInLeftBig" leave-active-class="fadeOut">
            <div class="pageThree_top" v-if="show">
                <div class="title">关于产品</div>
            </div>
        </transition>
        <div class="products clearfix">
            <div class="ProductItem fl" v-for="(item,index) in product" :key="index" @click="godetail(index)">
                <div class="item_left">
                    <p class="name">{{item.name}}</p>
                    <p class="english">{{item.english}}</p>
                    <P class="proBtn">查看详情<span class="p_biao">▶</span></P>
                </div>
                <div class="item_right">
                    <img :src="item.image" alt="">
                </div>
            </div>
        </div>
        <div class="btn btnBlue moreBtn" @click="goProduct()">查看更多 +</div>
    </div>
</template>

<script>
import {productConst} from '@/data/index'
export default {
    components: {},

    data() {
        return {
            product:[]
        };
    },
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },

    computed:{},

    mounted () {
        this.$nextTick(()=>{
            this.product = productConst.slice(0,4)
        });
    },

    methods: {
        goProduct(){
            this.$router.push('/product')
            
        },
        godetail(index){
            this.$router.push('/detail?index='+(index*1+1))
        }
    },
}
</script>

<style lang='scss' scoped>
.pageThreePage{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    .pageThree_top{
        text-align: center;
        .title{
            font-size: 30px;
            margin-bottom: 30px;
            color: #627afd;
        }
    }
}
.products{
    width: 1200px;
    .ProductItem{
        display: flex;
        justify-content: space-around;
        width:45%;
        padding:20px;
        background: #fff;
        // box-shadow:0px 6px 8px 2px rgba(8, 15, 36, 0.1);
        &:nth-child(odd){
            margin-right: 20px;
            margin-bottom: 20px;
        }
        &:nth-child(even){
            margin-bottom: 20px;
        }
        &:hover{
            box-shadow:0px 6px 8px 2px rgba(8, 15, 36, 0.1);
        }
        .item_left{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            cursor: pointer;
            .name{
                font-size: 28px;
                color: #004189;
            }
            .english{
                font-size: 26px;
                color: #3a3a3a;
                padding: 15px 0;
            }
            .proBtn{
                font-size: 15px;
                color: #004189;
                padding-top: 10px;
                .p_biao{
                    margin-left: 10px;
                }
            }
        }
        .item_right{
            width: 248px;
            height: 248px;
            border:1px solid #ddd;
            img{
                width:100%;
            }
        }
    }
}
</style>