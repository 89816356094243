<template>
    <div class="index">
        
        <full-page :options="options" id="fullpage" ref="fullpage">
            <div class="section relative">
                <div class="backStyle pageOne" :style="banner1"></div>
                <transition enter-active-class="animated lightSpeedIn" leave-active-class="fadeOut">
                    <div class="pageOne_wrap" v-if="show">
                        <div class="title">提供一站式服务</div>
                        <div class="name">分离分析整体解决方案</div>
                        <div class="english"> separation analysis overall solution </div>
                        <span class="btn" @click="goProduct">了解更多 +</span>
                    </div>
                </transition>
            </div>
            <div class="section relative">
                <div class="backStyle" :style="banner2"></div>
                <page-two :show="show"></page-two>
            </div>
            <div class="section relative">
                <div class="backStyle" :style="banner3"></div>
                <page-three :show="show"></page-three>
            </div>
            <div class="section relative">
                <div class="backStyle" :style="banner4"></div>
                <page-four :show="show"></page-four>
            </div>
            <div class="section relative">
                <div class="backStyle" :style="banner5"></div>
                <page-five :show="show"></page-five>
            </div>
            <div class="section" id="footer" ref="footer"> 
                <footer-bom></footer-bom>
            </div>
        </full-page>
    </div>
</template>
<script>
import footerBom from '@/components/footerBom.vue'
import PageTwo from './comments/pageTwo.vue'
import PageThree from './comments/pageThree.vue'
import pageFour from './comments/pageFour.vue'
import pageFive from './comments/pageFive.vue'
export default {
    components: { footerBom, PageTwo, PageThree,pageFour,pageFive},
    data() {
        return {
            show:false,
            banner1:{backgroundImage:"url("+require('@/assets/images/banner/banner1.png')+")"},
            banner2:{backgroundImage:"url("+require('@/assets/images/banner/banner2.jpg')+")"},
            banner3:{backgroundImage:"url("+require('@/assets/images/banner/banner3.png')+")"},
            banner4:{backgroundImage:"url("+require('@/assets/images/banner/banner4.jpg')+")"},
            banner5:{backgroundImage:"url("+require('@/assets/images/banner/banner5.jpg')+")"},
            options: {
                licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
                afterLoad: this.afterLoad,
                onLeave: this.onLeave,
                css3: true,
                fitToSection: true,//设置是否自适应整个窗口的空间，默认值：true
                scrollOverflow: true, //内容超过满屏后是否显示滚动条，true则显示滚动条
                keyboardScrolling: true,//是否可以使用键盘方向键导航，默认为true
                verticalCentered: true, //每一页幻灯片的内容是否垂直居中
                resize: true,//字体是否随着窗口缩放而缩放
                scrollingSpeed: 900,//页面滚动速度
                ontinuousVertical: true, //是否循环滚动，不会出现跳动，效果很平滑
                navigation: true, //是否显示导航，默认为false
                navigationPosition: "right", //导航小圆点的位置
                scrollBar: false,
                keyboardScrolling: true, //是否可以使用键盘方向键导航，默认为true
                menu: "#menu",
                slidesNavigation: true,
            },
        };
    },
    mounted(){
        this.$nextTick(()=>{
            this.show = true
        })
    },
    methods: {
        afterLoad(origin, destination, direction) {
            this.show = true
        },
        onLeave(index, nextIndex, direction){
            this.show = false
        },
        goProduct(){
            this.$router.push('/product')
        }
    },
};
</script>
<style>
    /* 右侧小圆点导航 */
    #fp-nav ul li a span, .fp-slidesNav ul li a span{
        width:8px !important;
        height:8px !important;
    }
    .fp-slidesNav ul li:hover a.active span{
         margin:0 !important
    }
    #fp-nav ul li a.active span{
        width: 14px !important;;
        height: 14px !important;;
        background: #627afd !important;
    }
    #fp-nav ul li a span{
        background: #a1cbf7;
    }
</style>
<style scoped lang="scss">
@import '@/assets/css/pageOne.scss';

.backStyle{
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}
#footer{
    height:auto !important;
    background: #000;
    /deep/.fp-tableCell{
        height: 300px !important;
    }
    .foot{
        max-width: 1200px;
        margin:0 auto;
        color:#fff;
        height:140px;
        padding:20px;
        .CopyRight{
            text-align: center;
            margin: 20px 0;
        }
        .foot_wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 50px;
            font-size: 18px;
            .phone, .contacts{
                margin-top:15px;
            }
        }
        .Icp{
            text-align: center;
            font-size: 12px;
            margin-top: 40px;
        }
    }
}

</style>